@media (min-width: 600px) {
  .video {
    height: 100%;
    position: fixed;
    left: 100;
    z-index: -1;
    filter: blur(4px);
    transform: scale(1.1);
  }
}

@media (min-width: 2400px) {
  .video {
    left: 2%;
  }
}