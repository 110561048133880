body {
  margin: 0;
  font-family: 'Poppins', 'Roboto', 'Helvetica Neue', 'Arial', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overscroll-behavior-x: none;
}

html {
  overscroll-behavior-x: none;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

.app {
  padding: 80px 20px;
}

::-webkit-scrollbar {
  width: 16px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #cbcbcb;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #acacac;
}

::-webkit-scrollbar-corner {
  background: rgba(0,0,0,0);
}

input[type="color"] {
  border-radius: 0px;
  padding: 0;
  border: 0px solid #DDD;
}

input[type="color"]::-moz-color-swatch {
  border: none;
}

input[type="color"]::-webkit-color-swatch-wrapper {
  padding: 0;
  border-radius: 0;
}

input[type="color"]::-webkit-color-swatch {
  border: none;
}